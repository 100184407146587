
/* Assuming you have a way to include custom CSS */
.card-container:hover .overlay,
.hover-link:hover ~ .imagehover .overlay::before {
  opacity: 0.25;
}

.card-container:hover .hover-link,
.imagehover:hover ~ .hover-link {
  text-decoration: underline;
}








