@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", Arial;
  line-height: 1.5rem;
  font-weight: 300;
  font-size: 1rem;
  scrollbar-width: thin;

  /* scrollbar-color: var(--secondary) var(--primary); */
}

/* Add this to the end of your global CSS file */
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.3s ease-out;
}

.perspective {
  perspective: 1000px;
}

.backface-hidden {
  backface-visibility: hidden;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.animate-scroll:hover {
  animation-play-state: paused;
}

p,
.custom-justify {
  text-align: justify;
  /* text-justify: inter-character; */
  /* text-justify:auto; */
  /* text-justify: distribute;  */
  hyphens: auto;
  word-spacing: 0;


}

.custom-center {
  text-align: center !important;
}

.app {
  /* height: 100vh; */
  width: 100%;


}

@media (max-width: 1024px) {
  .app {
    height: 100vh;
    width: 100%;
    overflow-y: auto;


  }
}

.nav_text_main {
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;

}

.nav_text {
  display: inline-block;
  color: #1F2022;
  text-transform: none;
  font-weight: bold;
  padding: 22px 10px;
  font-style: normal;

  transition: all 0.2s ease-out;
  font-size: 15px;
  letter-spacing: 0.06em;
  position: relative;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Source Sans Pro", Arial, Arial, sans-serif;

}


h1 {
  font-size: 32px;
  line-height: 1.25;
  color: #d95b25;
  margin-bottom: 15px;
  font-weight: 500;
}

h2 {
  font-weight: normal;
  font-size: 25px;
  line-height: 1.25;
  color: black;
  margin-bottom: 15px;
}

h3 {
  font-size: 19px;
  font-weight: 400;
  color: #333;
  line-height: 1.4em;
}

.desc {
  font-size: 18px;
  line-height: 150%;
  margin: 3px 0 10px;
  color: #333;
}

.career_text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4rem;
  color: #333;
}

p {
  margin: 3px 0 10px;
  font-size: 16px;
  line-height: 150%;
  color: #333;
}

li {
  text-decoration: none;
  list-style: none;
  margin: 3px 0 10px;
  font-size: 16px;
  line-height: 150%;
  color: #333;
}


/* home page */

.animation-element {
  opacity: 0;
  transition: all 500ms linear;
}

.slide-left {
  transform: translate3d(-100px, 0, 0);
}

.slide-right {
  transform: translate3d(100px, 0, 0);
}

.slide-up {
  transform: translate3d(0, 100px, 0);
}

.in-view {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}



@keyframes fadeInUp {
  from {
    opacity: 0.4;
    transform: translateY(80px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;
}

.animate-up.active {
  animation: fadeInUp 0.9s forwards;
}

/* career */

/* Global styles for text color and responsiveness */
.career_main {
  position: relative;
  color: #fff;
}

.career_banner {
  text-align: center;

}

/* Desktop Styles (min-width 1024px) */
@media screen and (min-width: 1024px) {
  .career_banner {
    position: absolute;
    left: 4%;
    /* Slight margin for left alignment */
    top: 30%;
    font-size: 36px;
    /* Larger font for desktop */
    padding: 0 20px;
    text-align: left;
  }
}

/* Tablet and Smaller Desktops (768px to 1024px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .career_banner {
    position: absolute;
    left: 3%;
    bottom: 5%;
    font-size: 28px;
    /* Font size adjustment for tablets */
    padding: 0 10px;
    text-align: center;
  }
}

/* Mobile Styles (max-width 768px) */
@media screen and (max-width: 768px) {
  .career_banner {
    position: absolute;
    width: 100%;
    bottom: 5%;
    /* Align the text at the bottom of the image */
    padding: 0 15px;
    font-size: 32px;
    /* Slightly smaller for mobile */
  }
}

/* Small Mobile (320px to 480px) */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .career_banner {
    font-size: 18px;
    /* Further reduce font size for smaller phones */
    padding: 0 10px;
  }
}












/* webinar */

.webinar_main {
  background: url(../src/Assets/events-banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  display: flex;

  align-items: center;
}

.event_main {
  background: url(../src/Assets/events-backgroundbanner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding-top: 150px;
  filter: drop-shadow(8px 8px 10px gray);

}

/* partner & alianzes */

.partner_main {
  background: url(../src/Assets/paralizebgimg.jpg);
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glassdoor_career {
  background: url(../src/Assets/glassdoor-careers-section.png) center center / cover no-repeat;
  height: auto;
  /* Let height adjust naturally */
  min-height: 350px;
  /* Ensures there's always enough height on smaller screens */
  padding: 20px;
  /* Adds padding to keep content away from edges */
  border-radius: 8px;
  /* Adds rounded corners */
}



/* Leadership page */
.card {
  position: relative;
  width: 300px;
  /* Adjust the width as needed */
  margin: 20px auto;
  /* Center the card horizontally */
  box-shadow: 8px 7px 6px 4px rgba(0, 0, 0, 0.3);



  border-radius: 8px;
  /* Optional for rounded corners */
  overflow: hidden;
  /* To ensure no overflow of content */

}

.image-container {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  /* Adjust the position as needed */
  left: 10px;
  color: #fff;
  /* Text color */

  padding: 10px;
  border-radius: 5px;
  /* Optional for rounded corners */
}

.card-text .content {
  padding: 15px;
  font-size: 16px;
  /* Adjust the font size as needed */
  color: #fff;
  /* Text color */
}





/* if you want hover with text on image use this css */
.image-container {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  width: 100%;
  /* height: auto; */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 100%; */
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

/* if you want hover with text on image use this css  end*/
dl::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(360deg, #4e8ecb 0%, #b41f70 50%, #ffd626 100%);
}

/*  this is for box when image is hover then only it will be working */

.imagehover {
  position: relative;
  background-color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}

.imagehover:hover:before {
  content: "";
  opacity: 1;
  position: absolute;
  width: 45px;
  height: 45px;
  left: 0;
  bottom: 0;
  top: 30;
  background-color: white;
  z-index: 20;
  transition: all 0.2s ease;
}

.imagehover:hover::after {
  content: "";
  opacity: 1;
  position: absolute;
  width: 80px;
  height: 80px;

  left: 0;
  bottom: 0;
  background: linear-gradient(360deg, #df6550 0%, #f48128 50%, #ce2029 100%);
  z-index: 10;
  transition: all 0.2s ease;
}

.imagehover:hover .overlay {
  opacity: 0.5;
}


/*  this is for constant box for the images */
.image_design {
  position: relative;
  display: inline-block;
}

.image_design::before {
  content: "";
  opacity: 1;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 0;
  bottom: 0;
  background-color: #efefef;
  z-index: 10;
  transition: all 0.2s ease;
}

.image_design::after {
  content: "";
  opacity: 1;
  position: absolute;
  width: 150px;
  height: 150px;
  left: 0;
  bottom: 0;
  /* linear-gradient(to right, #4e8ecb, #6a75ab) */
  background: linear-gradient(360deg, #4e8ecb 0%, #6a75ab 50%, #b41f70 100%);
  transition: all 0.2s ease;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.blog_Content::before {
  display: inline-block;
  content: "";
  font-size: 24px;
  border-top: 1px solid black;
  width: 12px;
  top: -4px;
  position: relative;
  margin: 0 4px 0 2px;

}

/* Leadership */





/* contactus */
.contant_main {
  background-image: url(../src/Assets/contact_us.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  width: 100%;
}

.contact_form {
  background-color: #f1f1f1 !important;
  display: block;
  width: 100%;
  height: 50px;
  padding: 12px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #999;
  border: none;
  border-radius: 5px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.contact_form:focus {
  border-radius: 5px;
  border: 1px solid #4e8ecb;
  outline: 0;

}

@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .contant_main {

    background-size: contain;
    height: 40vh;
  }
}

@media (max-width: 480px) {
  .contant_main {
    background-position: top;
    background-size: contain;
    height: 30vh;
  }
}


/* Responsive adjustments */
@media (max-width: 1024px) {

  /* Adjust the breakpoint for tablets */
  .image_design::before {
    width: 80px;
    height: 80px;
    left: 0;
    bottom: 0;
  }

  .image_design::after {
    width: 120px;
    height: 120px;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {

  /* Adjust the breakpoint for small tablets and mobile screens */
  .image_design::before {
    width: 60px;
    height: 60px;
    left: 0;
    bottom: 0;
  }

  .image_design::after {
    width: 90px;
    height: 90px;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 480px) {

  /* Adjust the breakpoint for very small mobile screens */
  .image_design::before {
    width: 50px;
    height: 50px;
    left: 0;
    bottom: 0;
  }

  .image_design::after {
    width: 70px;
    height: 70px;
    left: 0;
    bottom: 0;
  }
}